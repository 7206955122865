import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Pagination from '@components/Pagination'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import "./Posts.css"

const Posts = ({
  data: { featuredPosts = {}, paginatedPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {}, basePath } = {} } = props

  return (
    <Layout {...props}>
      <Seo title='Home' />
      {props.location.pathname === basePath && (
        <>
          <Divider />
          <Stack
            effectProps={{ effect: false }}
            title='Suggested Articles'
            direction={[`column`, `column`, `column`, `row`]}
          >
            <Main>
              <CardList
                nodes={featuredPosts.nodes}
                limit={6}
                columns={[1, 2, 2, 3]}
                variant={[
                  'vertical'
                ]}
              />
            </Main>
          </Stack>
        </>
      )}
      <Divider />
      <Stack title='Recently Published'>
        <Main>
          <CardList
            nodes={paginatedPosts.nodes}
            variant={['vertical']}
            columns={[1, 2, 3, 3]}
            
          />
        </Main>
      </Stack>
      <Divider />
      <PreFooter className="pre-footer">
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
        {services.mailchimp && (
          <>
            <Divider />
            <NewsletterExpanded simple />
          </>
        )}
      </PreFooter>
    </Layout>
  )
}

export default Posts
